import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Terms of Service"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Thank you for your interest in Drivit, a service developed by “Bahub Business Analysis Systems, Lda.” (“Bahub”, “we”, “us”). By using the “Service”, which comprises all the Drivit related applications, websites (including but not limited to `}<a parentName="p" {...{
        "href": "https://www.drivit.com",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.drivit.com`}</a>{`, and other services and information products, you are entering into a legal contract with us which includes these Terms of Service and our `}<a parentName="p" {...{
        "href": "/privacy"
      }}>{`Privacy Policy`}</a>{`.`}</p>
    <p>{`We tried hard to be as transparent and reasonable as we could, but should you have any doubts or questions, please don’t hesitate to contact as at `}<a parentName="p" {...{
        "href": "mailto:support@drivit.com",
        "target": "_blank",
        "rel": "noopener"
      }}>{`support@drivit.com`}</a>{`.`}</p>
    <h2>{`1. Introduction`}</h2>
    <p>{`“Drivit” (hereinafter referred to as Drivit or Service) is a mobile service developed to improve your day-to-day driving experience. The application connects with your car by using an OBD II Bluetooth adapter and allows you to: i) Log your trips and know how several key indicators have been evolving; ii) complement your car’s dashboard by providing real time information of several of your car’s sensors; iii) make an initial diagnosis of potential problems that your car might have registered; iv) offer an additional set of driving related features that are meant to make your life easier. Although some of these functionalities are not fully implemented at this time, they will be at some point in the near future, solely at our discretion.`}</p>
    <h2>{`2. Account`}</h2>
    <p>{`To access and use most of the Service features, you must create and maintain an active personal user account (“Account”). You warrant and represent, before creating this account or using any part of the Service, that you have the right, authority and capacity to understand, accept and agree to these Terms of Service. You represent that you are in compliance with all the legal provisions in your jurisdiction. You declare that you are of sufficient legal age in your jurisdiction or residency to use the Service and to enter this agreement.`}</p>
    <p>{`The Account registration requires you to submit to Bahub certain personal information, such as your name, valid email and your vehicle(s) details (refer to our Privacy Policy for an extensive description). You agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to do so, may result in your inability to access and use the Services and, ultimately, the termination of this Agreement between you and Bahub. You are responsible for all activity that occurs under your Account, and, as such, you agree to maintain the security and secrecy of your Account username and password at all times. If you lose your account credentials, if they are stolen or if you believe that there was unauthorized access to your account, please contact us immediately and change your password as soon as possible.`}</p>
    <h2>{`3. Service Requirements`}</h2>
    <p>{`The Service, including some of its features, may not be accessible without: i) an Account; ii) mobile clients such as a supported phone; iii) an OBDII compatible adapter which allows the communication between the car engine control unit (ECU) and the mobile client using Bluetooth; iv) a working Wi-Fi network or data plan that provides a reliable always-on access to the Internet; v) other system elements that may be specified at our own discretion. It is your responsibility to ensure that you have all required system elements and that they are compatible and properly configured. Nevertheless, whether these requirements are met or not, you acknowledge that the Service may not work as described due to several factors, both internal and external, which may compromise its usage.`}</p>
    <p>{`The use of our Service is limited to vehicles which comply with the European On-Board Diagnostics (EOBD) regulations (or the US equivalent OBD-II standard). This generally applies to vehicles registered within the EU member states from 1st January 2001, for Petrol vehicles, and from 1 st January 2004 for Diesel vehicles.`}</p>
    <p>{`In exchange for the Service provided under these terms, users allow us to: a) use the processor, broadband and storage of your device, in order to improve the Service and; (b) process personal data in accordance with our Privacy Policy.`}</p>
    <h2>{`4. Acceptance of Terms of Service`}</h2>
    <p>{`The Terms of Service may be modified as the Service continues to evolve over time, and we advise you to check our website on a regular basis for possible updates. The most current version of the document will be available in the “User Account” menu on the Drivit mobile application. You will be notified via the email address that you have associated with your Account, and/or by posting a notice on the website prior occurrence of any changes to this document.`}</p>
    <p>{`This is a legal agreement and by using Drivit you acknowledge and agree to be bound by the Terms of Service in force at that time, including any changes that have been made. Therefore, you accept and acknowledge that this document may be updated regularly and that it is your sole responsibility to understand what version of the Terms of Service is in force whenever you use the Service. If you do not understand, are in doubt, or do not agree with any of the provisions in this document, you may not use our Service.`}</p>
    <h2>{`5. Access and Use of the Service`}</h2>
    <p>{`For purposes of these Terms of Service, the term “Content” includes, without limitation, any information, data, text, design, photographs, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible on or through the Service.`}</p>
    <p>{`To the extent permitted through the Service, you may have the ability to post, submit, email or otherwise make available on the Services certain information, text, materials or any other Content ("Your Content").`}</p>
    <p><strong parentName="p">{`5.1. You License to Bahub`}</strong></p>
    <p>{`You retain ownership of all your information, however, you grant us a worldwide, non-exclusive, royalty-free license to use this information in the terms disclosed in our Privacy Policy.`}</p>
    <p><strong parentName="p">{`5.2. Bahub Licenses to You`}</strong></p>
    <p>{`Drivit and its Contents belong to Bahub. Subject to these Terms of Service, Bahub grants each user of the Service a worldwide, non-exclusive, non-sublicensable, non-transferable, limited and revocable license to personal and non-commercial use (i.e., to download and display locally).`}</p>
    <p>{`You shall not sell, license, rent, or otherwise use or exploit any Content or part of the Service for commercial use. Any use, reproduction, modification, distribution or storage of any of our Content for other purposes is expressly prohibited without prior written permission from us.`}</p>
    <p>{`Drivit and its Content are licensed, not sold, to the user, and Bahub keeps its property and the property of all software applications copies even after its download on your device.`}</p>
    <p>{`All trademarks, service marks, trade names, logos, domain names and any other uses of Bahub brands are exclusively property of Bahub. This license does not allow the use of trademarks, service marks, trade names, logos, domain names and any other uses of Bahub brands, for any use, commercial or otherwise.`}</p>
    <h2>{`6. Availability of Service`}</h2>
    <p>{`We will make all efforts to keep the Service operational and reliable as we believe it is an essential part of our business and, more importantly, of your experience. However, certain technical problems may lead to temporary interruptions of the Service. We reserve the right to, at any moment, modify or interrupt, temporary or permanently, Service functions, with or without notice.`}</p>
    <p>{`In these cases, we will not be liable before the user, but within our operational possibilities we will try to assist you directly (e.g. costumer service) or indirectly (e.g. community forum) and attempt to improve or update the Service in order to provide the best possible user experience.`}</p>
    <p>{`We may modify, suspend or discontinue the Service and any of its Content, features or any part thereof without notice and you agree that we are not be liable to you or to any third party for any modification, suspension, or discontinuance of the Services or any part thereof.`}</p>
    <p>{`Our Service may not be available to all persons in all countries. If you choose to access the Service from outside our supported countries (the Service is currently limited to Portugal), you do so at your own responsibility and acknowledge and agree that it was not designed for that use and that it may not work or be appropriate to use in such country.`}</p>
    <h2>{`7. Rules of Conduct`}</h2>
    <p>{`As a condition of use of our Service, you guarantee us that you will not to use the Service for any purpose that is prohibited by these Terms of Service or by the law.`}</p>
    <p><strong parentName="p">{`7.1.`}</strong>{` You are the sole responsible for all of your activity in connection with the Service, and you warrant us that Your Content:`}</p>
    <p>{`a) does not infringe any patent, trademark, trade secret, copyright, right of publicity or other right of any other person or entity or violates any law or contractual duty;`}</p>
    <p>{`b) is not untruthful or inaccurate, unlawful, threatening, abusive, obscene, harassing, defamatory, fraudulent or disrespectful to any individual (according to our sole discretion);`}</p>
    <p>{`c) does not comprise any computer codes, files, or programs that are designed or intended to disrupt, damage, obtain unauthorized access to, limit or interfere with the proper function of any software or hardware, of ours or of any third party;`}</p>
    <p><strong parentName="p">{`7.2.`}</strong>{` Additionally, you shall not:`}</p>
    <p>{`a) interfere or attempt to interfere with the proper working of the Service (as determined by us at our sole discretion) or with any activities conducted on the Service;`}</p>
    <p>{`b) attempt to bypass any measures we may use to prevent or restrict access to or in relation to the Service;`}</p>
    <p>{`c) run any form of auto-responder or “spammer”, “crawler” or “spider” on the Service.`}</p>
    <p>{`d) decipher (directly or indirectly), decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service (including without limitation any application owned by Bahub). We reserve the right to, but do not have any obligation to, remove, block, edit or modify any of your Content at our sole discretion, at any time, without notice to you and for any reason, including, but not limited to situations where, upon receipt of claims or allegations from third parties or authorities relating to such Content or if we are concerned that you may have violated these Terms of Service.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      